<template>
<div id="desktopNav">
  <div v-if="$store.state.logged == false" class="navOptions1">
<div class="optionsBox1">

  <router-link to="/ingresar"  v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish'" class="enlace" active-class="activo1" exact-active-class="exacto">ingresar</router-link>
  <router-link to="/registrarse"  v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish'"  class="enlace" active-class="activo1" exact-active-class="exacto">registrarse</router-link>

  <router-link to="/ingresar"  v-if="!$store.state.idioma == false && $store.state.idioma == 'english'" class="enlace" active-class="activo1" exact-active-class="exacto">sign in</router-link>
  <router-link to="/registrarse"  v-if="!$store.state.idioma == false && $store.state.idioma == 'english'"  class="enlace" active-class="activo1" exact-active-class="exacto">sign up</router-link>
</div>
 </div>
 <div v-if="$store.state.logged == true" class="navOptions1">
<div class="optionsBox1">
  <a class="enlace"  v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish'" v-on:click="$store.dispatch('cerrarSesion')">Cerrar sesión</a>
  <a class="enlace"  v-if="!$store.state.idioma == false && $store.state.idioma == 'english'" v-on:click="$store.dispatch('cerrarSesion')">Log out</a>
</div>

 </div>
 <div class="userData">
  <img src="../assets/eagleworldblack.png" alt="" v-if="$store.state.isRWC==false">
  <img src="../assets/coin-circulo.png" alt="" v-if="$store.state.isRWC==true">
  
  <div  id="usuario">
      <h3 id="usuarioDesconectado" v-if="$store.state.logged ==true && !$store.state.idioma == false && $store.state.idioma == 'spanish' && $store.state.isRWC==false">¡Bienvenido {{ $store.state.usuario }}!</h3>
      <h3 id="usuarioDesconectado" v-if="$store.state.logged ==true && !$store.state.idioma == false && $store.state.idioma == 'english' && $store.state.isRWC==false">¡Welcome {{ $store.state.usuario }}!</h3>
      <h3 id="usuarioDesconectado" v-if="$store.state.logged ==true && !$store.state.idioma == false && $store.state.idioma == 'spanish' && $store.state.isRWC==true">¡Bienvenido {{ $store.state.usuario }}, RWC holder!</h3>
      <h3 id="usuarioDesconectado" v-if="$store.state.logged ==true && !$store.state.idioma == false && $store.state.idioma == 'english' && $store.state.isRWC==true">¡Welcome {{ $store.state.usuario }}, RWC holder!</h3>
      <h3 id="usuarioDesconectado" v-if="$store.state.logged !==true && !$store.state.idioma == false && $store.state.idioma == 'spanish'">¡Bienvenido a raptoreum world!</h3>
      <h3 id="usuarioDesconectado" v-if="$store.state.logged !==true && !$store.state.idioma == false && $store.state.idioma == 'english'">¡Welcome to raptoreum world!</h3>
  </div>

 </div>
 <div class="navOptions2">
  <router-link to="/"  v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish'" class="enlace2" active-class="activo2" exact-active-class="exacto">Inicio</router-link>
  <router-link to="/"  v-if="!$store.state.idioma == false && $store.state.idioma == 'english'" class="enlace2" active-class="activo2" exact-active-class="exacto">Home</router-link>
  <router-link to="/assetsmarket" v-if="!$store.state.idioma == false && $store.state.idioma == 'english'" class="enlace2" active-class="activo2" exact-active-class="exacto">coin market</router-link>
  <router-link to="/assetsmarket" v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish'" class="enlace2" active-class="activo2" exact-active-class="exacto">coin market</router-link>
  <router-link to="/transactions" v-if="!$store.state.idioma == false && $store.state.idioma == 'english'" class="enlace2" active-class="activo2" exact-active-class="exacto">rewards</router-link>
  <router-link to="/transactions" v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish'" class="enlace2" active-class="activo2" exact-active-class="exacto">recompensas</router-link>
  <router-link to="/nftmarket" v-if="!$store.state.idioma == false && $store.state.idioma == 'english'" class="enlace2" active-class="activo2" exact-active-class="exacto">NFT market</router-link>
  <router-link to="/nftmarket" v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish'" class="enlace2" active-class="activo2" exact-active-class="exacto">mercado NFT</router-link>
  <router-link to="/contest" v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish'" class="enlace2" active-class="activo2" exact-active-class="exacto">concurso</router-link>
  <router-link to="/contest" v-if="!$store.state.idioma == false && $store.state.idioma == 'english'" class="enlace2" active-class="activo2" exact-active-class="exacto">constest</router-link>

  <div class="enlace3">
  <h1 style="font-size: small;">Select your language:</h1>
  <div style="display: flex;justify-content: center;align-items: center;">
    <img src="../assets/españa.png" v-on:click='$store.dispatch("setTokenSpanish")' style="height: 4vh;width:4vh;cursor: pointer;" alt="">
    <h3 style="cursor: pointer;" v-on:click='$store.dispatch("setTokenSpanish")'>Spanish</h3>
  </div>
  <div style="display: flex;justify-content: center;align-items: center;">
    <img src="../assets/english.png" v-on:click='$store.dispatch("setTokenEnglish")' style="height: 4vh;width:4vh;cursor: pointer;" alt="">
    <h3 style="cursor: pointer;" v-on:click='$store.dispatch("setTokenEnglish")'>English</h3>
  </div>
  </div>
</div>
</div>

</template>
<script>

export default {
      name: 'desktopNav',
      components: {
      
  },
  data(){
        return{
          message: "el socket no ha funcionado",
          times:[],
          encuestasRespondidas:0,
          usuario:localStorage.getItem("usuario") || false,
          token:localStorage.getItem("token") || false,
        }
      },
  mounted() {
//hacer validacion de la data del localstorage
  },
  beforeDestroy() {
    
  },methods:{
  closeMobileNav:()=>{
    document.querySelector("#navbarNav").classList.toggle("show")
    },

      
    }


     }
</script>
<style scoped>
/* 
ESTILOS:
font-family: 'Lexend Peta', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Russo One', sans-serif;
*/
* {
    /* Estilos para navegadores basados en Webkit (Chrome, Safari) */
    ::-webkit-scrollbar {
        width: 8px; /* Ancho del scrollbar */
    }

    ::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0); /* Fondo del track del scrollbar con transparencia */
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0); /* Fondo del track del scrollbar con transparencia */
        border-radius: 10px; /* Redondeo del thumb */
        border: 2px solid rgba(255, 255, 255, 0.9); /* Borde blanco con un poco de transparencia */
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0);/* Fondo del track del scrollbar con transparencia */
    }

    /* Estilos para Firefox */
    scrollbar-width: thin; /* Ancho del scrollbar: puede ser auto, thin o none */
    scrollbar-color: rgba(255, 255, 255) rgba(0, 0, 0); /* Color del thumb y del track */
}
html {
  font-size: 16px; /* Esto significa que 1rem = 16px */
}

@media (max-width: 1200px) {
  html {
    font-size: 14px; /* Reduzca el tamaño de la fuente en pantallas más pequeñas */
  }
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 10px;
  }
}
#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

}


li h1{
  font-size: large;
}
img{
  animation: spin 8s infinite linear;
  height: 28vh;
}
@keyframes spin {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
#serviciosDropdown{
  font-size:3vh;
  margin-top: 3vh;
}

#desktopNav{
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      top: 0;
      left: 0;
      height: 100vh;
      min-width:20vw;
      max-width:20vw;
      background-color: #fff; 
    }
    .dropdown{
      display: flex;
      justify-content: center;
      align-items: center;
      width:20vw;
    }
.userData img{
  margin:2vh
}
.nav div{

  background-color:#fff;
}
#mobileNav{
  display: none;
  
}
#desktopNav ul{
  width: 100%;
  text-align: center;
}

#desktopNav div{
max-width: 100%;

}
#desktopNav h2{
max-width: 20vw;
word-wrap: break-word;
}


#desktopNav img{
max-width: 20vw;
}

.enlace{
color: #000;
font-weight: 900;
font-size: 2vh;
text-decoration: none;
cursor: pointer;
border: 2px solid #000;
}
.enlace2{
  cursor: pointer;
color: #fff;
font-size: 1.25rem; 
border-bottom: 2px solid #fff;
text-decoration: none;
}
.userData{
  height: 50vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  
}
.userData img{
  height: 30vh;
  width: 30vh;
}
div a{
  width: 20vw;
}
.userData h1{
font-size: x-large;
text-align: center;
}
.navOptions2{
  height: 50vh; 
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
}

.enlace3{
  display: flex;
  justify-content: space-around;
  flex-direction:column;
align-items: center;
margin-top:17%;
cursor: pointer;
color: #fff;
font-size: 3vh;
text-decoration:none;
}
.navOptions1 .enlace{
  height: min-content; 

  background: linear-gradient(to top, #666, white); 
  display: flex;
  flex-direction: row;
  justify-content:center;
  margin:2vh ;
flex-wrap: wrap;
  align-items: center;
  font-size:2vh;
}
.navOptions1 div {
  height: 9vh; 
overflow: hidden;
}

.optionsBox1{
  height: 9vh; 
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 20vw;

  background: linear-gradient(to top, #666, white); 
  color: #fff;
}

.optionsBox2{
  height: 50vh; 
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 20vw;
  background-color: transparent;
  color: #fff;
}
.activo2{
  
  background: linear-gradient(to top, #666, white); 
  color: #000;
  font-weight: bold;
}

@keyframes apear {
  0% {opacity: 0; }
  100% {opacity: 2;  }
}
/*subirlo de 980 haria tener problemas al mobilenav*/
@media (max-width:980px) {
  #mobileNav{
  display: flex;
  background-color: #fff;
}
#desktopNav{
  display: none;
}

}
#usuario h1,h3{
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size:20px ;
}

.mensaje{
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size:20px ;
}
</style>

